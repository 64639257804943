import React from 'react'
import img from '../image/rak1.png'

import CV from '../image/CV.pdf'

function Home() {
  return (
    <main>
      <section>
        <div className='maindiv'>
          <div className='leftside'>
            <div className='text'>
              <span>Hello</span>
              <div className='line'>
              </div>
            </div>
            <div className='name'>I'm <span>Civil Engineer</span></div>
            <div className='det text-center fs-6'>Seeking a career is challenging and interesting, and let's me work on the leading areas of a job that gives me the opportunities to learn, innovate and enhance my skills and strength in conjunction with my company goals and objectives.</div>
            <div className='cv'><a href={CV} download='CV'>
            Download CV
              </a>
              </div>
          </div>
          <div className='rightside'>
            <div className='hexagon'>
              <img src={img} alt='img' />
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Home