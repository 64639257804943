import img from '../image/img.png'
import imgbg from '../image/bgabout.png'
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

function About() {
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === 75) {
        clearInterval(interval);
      } else {
        setCounter((prevCounter) => prevCounter + 1);
      }
    }, 20);

    return () => clearInterval(interval);
  }, [counter]);

  const [counter1, setCounter1] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter1 === 65) {
        clearInterval(interval);
      } else {
        setCounter1((prevCounter) => prevCounter + 1);
      }
    }, 20);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [counter1]);

  const [counter2, setCounter2] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter2 === 50) {
        clearInterval(interval);
      } else {
        setCounter2((prevCounter) => prevCounter + 1);
      }
    }, 20);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [counter2]);
  return (
    <main>
      <section>
        <div className='rightleftside'>
          <div className='aboutrightside'>
            <img src={imgbg} alt='img' className='bgimg' />
            <img src={img} alt='img' className='img1' />
            <div className="justify-content-end me2">
            </div>
          </div>
          <div className='aboutright'>
            <div className='tittle'>About Me</div>
            <div className='about'>
              <p className='tittle-span  text-center  fs-4'> I am Rakesh Kumar, a Civil Engineer with a Bachelor's degree from Rabindranath Tagore University. My academic excellence is demonstrated through achievements in the Intermediate Examination (BSEB 2019) and Secondary School Leaving Certificate (BSEB 2017). Proficient in <span>AutoCAD</span>, <span>STAAD Pro</span>, and <span>3D MAX</span>, I bring a solid foundation in design and modeling to complement my field experience. Eager to contribute my skills and expertise to innovative engineering projects.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bsb-skill-1  py-3 py-md-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-4 display-5 text-center">Skills</h2>
              <p className=" mb-5 text-center lead fs-4">Proficient in utilizing industry-leading design and drafting software, including AutoCAD for precise 2D and 3D drafting, STAAD Pro for structural analysis and design, and 3D MAX for creating visually stunning and realistic 3D models. Adept at leveraging these tools to contribute to the successful execution of engineering and design projects.</p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
            </div>
          </div>
        </div>

        <div className="container overflow-hidden">
          <div className="row justify-content-xl-center gy-3 gy-sm-4">
            <div className="col-12 col-sm-6 col-xl-5">
              <div className="bg-white rounded shadow-sm p-3 p-md-4 p-xxl-5">
                <h3 className="fw-bold mb-2">AUTOCAD</h3>
                {/* <p className="text-secondary fst-italic mb-4"></p> */}
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated progress-bar-width-1" role="progressbar" aria-label="Bootstrap" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" id='a1'></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-5">
              <div className="bg-white rounded shadow-sm p-3 p-md-4 p-xxl-5">
                <h3 className="fw-bold mb-2">STAAD PRO</h3>
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated progress-bar-width-2" role="progressbar" aria-label="React" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id='a11'></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-5">
              <div className="bg-white rounded shadow-sm p-3 p-md-4 p-xxl-5">
                <h3 className="fw-bold mb-2">3D MAX</h3>
                <div className="progress">
                  <div className="progress-bar progress-bar-striped progress-bar-animated progress-bar-width-3" role="progressbar" aria-label="Vue" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" id='a111'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="ag-format-container">
          <div className="ag-courses_box">
            <div className="ag-courses_item">
              <Link to="#" className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>
                <div className="ag-courses-item_title">
                  <div className='education1'>EDUCATION</div>
                  <div className='eduction-span'>
                    <div className='nameedu'>BACHELOR OF ENGINEERING 2019-2023</div>
                    <div className='nameedu1'>Rabindranath Tagore University</div>
                    <div className='nameedu'>Intermediate Examination BSEB 2019</div>
                    <div className='nameedu1'>Secondary school leaving certificate BSEB 2017</div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="ag-courses_item">
              <Link to='#' className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>
                <div className="ag-courses-item_title">
                  <div className='workExperience'>WORK EXPERIENCE</div>
                  <div className='.work-span'>
                    <div className='nameedu'>SCG CONTRACT INDIA PRIVATE LIMITED</div>
                    <div className='nameedu11'><span className='spantittle'>Project</span> :- New factory Building for Haldiram Snacks sec 155 Noida up India</div>
                    <div className='nameedu11'><span className='spantittle'>Client</span> - Haldiram snacks pvt. Ltd.</div>
                    <div className='nameedu11'><span className='spantittle'>Consultant</span> - Studio Praxis -</div>
                    <div className='nameedu11'><span className='spantittle'>DESIGNATION</span> - SITE ENGINEER</div>
                    <div className='nameedu11'><span className='spantittle'>Duration</span> - August 2023 to till date</div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="ag-courses_item">
              <Link to='#' className="ag-courses-item_link">
                <div className="ag-courses-item_bg"></div>
                <div className="ag-courses-item_title">
                  <div className='Internship'>INTERNSHIP</div>
                  <div className='nameedu'>I have done my internship from PWD Building structural work.</div>
                  <div className='nameedu1'>SCG CONTRACTS INDIA PRIVATE LIMITED</div>
                  <div className='nameedu'>Designation training engineer</div>
                  <div className='nameedu1'>N.S.D.C (National Skills Development Corporation)</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </main >
  )
}

export default About




