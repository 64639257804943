import * as React from "react";
import inst from '../image/instagram.svg';
import link from '../image/linkedin.svg';
import loc from '../image/location.svg';
import { Link } from "react-router-dom";

function Navbar() {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
            <div className="container-fluid">
                <div className="logofile">
                    <div>Rakesh Kumar</div>
                    <div className="dot"></div>
                </div>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                    <div className="navbar-nav me">
                        <Link className="nav-link fontme" aria-current="page" to=''>Home</Link>
                        <Link className="nav-link fontme" to='/about'>About</Link>
                        <Link className="nav-link fontme" to='/contacts'>Contacts</Link>
                    </div>
                    <div className="justify-content-end me1">
                        <Link to='https://www.linkedin.com/in/rakesh-kumar-992082256' target="_blank">
                            <img src={link} alt="img" className="img" />
                        </Link>
                        <img src={inst} alt="img" className="img" />
                        <Link target="_blank" to='https://www.google.com/maps/place/Sheikhpura,+Bihar+811105/@25.1384844,85.8294436,14z/data=!3m1!4b1!4m6!3m5!1s0x39f241577b68efe3:0xba1bf1c5da27bbcd!8m2!3d25.1391653!4d85.8392151!16zL20vMDVwbmxx?entry=ttu'><img src={loc} alt="img" className="img" /></Link> 
                    </div>
                </div>
            </div>
        </nav>

    )
}

export default Navbar